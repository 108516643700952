<template>
  <div class="wrapper">
    <b-container>
      <b-row align-h="center">
        <h3>The recognition you deserve to achieve the status you desire</h3>
      </b-row>
  
      <b-row align="start">
        <p>
          DistinguishedSociety is a personal branding consulting firm that
          specializes in building social recognition, a solid reputation and a
          great first impression through honorary academic titles, prestigious
          international awards, and chivalry and nobility titles tailored to
          your needs.
        </p>
      </b-row>

      <b-row>
        <b-card-group deck>
          <b-card title="Honorary Academic Titles">
            <b-card-text
              >An honorary doctoral degree bestowed as an honor from licensed
              and highly ranked awarding institutions – perfect to solidify your
              accomplishments.</b-card-text
            >
          </b-card>

          <b-card title="Chivalry & Nobility Titles">
            <b-card-text
              >Legitimate chivalry and nobility titles from the most-coveted
              European countries with centuries of history and tradition –
              perfect to elevate your social status, instantly.</b-card-text
            >
          </b-card>

          <b-card title="Prestigious International Awards">
            <b-card-text
              >State-level awards in the United States, United Kingdom and other
              countries as well as memberships at renowned organizations and
              fraternities – which will give your personal brand a distinguished
              boost.</b-card-text
            >
          </b-card>
        </b-card-group>
      </b-row>

      <b-row>
        <h3>Why Choose DistinguishedSociety?</h3>
      </b-row>

      <b-row align="start">
        <ul>
          <li>Decades of experience in personal branding</li>
          <li>A team with experience, knowledge and connections</li>
          <li>
            A comprehensive approach that includes academic titles, chivalry and
            nobility titles, as well as prestigious international awards and
            memberships
          </li>
          <li>Build a great first impression</li>
          <li>Express high status instantly</li>
          <li>Open new personal and professional opportunities</li>
        </ul>
      </b-row>

      <b-row>
         <b-card title="Media Placement">
            <b-card-text>
              Leverage your image and messages into the new height-we bring top print, digital, televised, podcasts, radio, etc., to increase your popularity.
            </b-card-text>
          </b-card>
      </b-row>

      <b-row></b-row>
      <b-row></b-row>
      <b-row></b-row>
    </b-container>
    
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      value: "",
      context: null,
      isCalendar: false,
      form: {
        name: "",
        phone: "",
      },
    };
  },
  methods: {
    onContext(ctx) {
      this.context = ctx;
    },
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
  },
};
</script>

<style scoped>
.wrapper{
  position: relative;
  z-index:1;
  overflow: hidden;
}

.wrapper::before{
  content: "";
  z-index:-1;
  background: #fff;
  position:absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: .2;
}

.wrapper::after{
  content: "";
  z-index:-2;
  position:absolute;
  background-image: url("../assets/home-blured.png");
  background-repeat: no-repeat;
  background-position: right 5% bottom 5%;;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: 700px;
  opacity:0.6;
}

.container > .row {
  margin: 100px 0;
}

.img-home {
  width: 600px;
}

.card {
  background-color: rgba(255, 255, 255, 0.6);
}

.schedule-card {
  padding: 2rem 0;
}
</style>
