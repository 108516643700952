<template>
  <footer class="hello">
    <b-container>
      <b-row align-h="around">
        <b-col align="start" md="auto" class="mt-4">
          <p><b>Contact</b></p>
          <ul>
            <li></li>
            <li>Email: distinguishedsociety@yahoo.com</li>
            <li>
              Social:
              <b-link
                href="https://www.facebook.com/DistinguishedSociety-101659095298537"
                target="blank"
              >
              <b-icon icon="facebook" aria-hidden="true"></b-icon>
              </b-link>

              <b-icon icon="linkedin" aria-hidden="true"></b-icon>
              
              <b-link
                class="instagram"
                href="https://www.instagram.com/distinguishedsociety2021/?igshid=p421av5bdmn"
                target="blank"
              >
              <b-icon icon="instagram" aria-hidden="true"></b-icon>
              </b-link>
            </li>
          </ul>
        </b-col>

        <b-col align="start" md="auto" class="mt-4">
          <p><b>Newsletter</b></p>
          <b-form inline @submit="onSubmit" v-if="show">
            <label class="sr-only" for="inline-form-input-email">Email</label>
            <b-input-group prepend="@" class="mb-2 mr-sm-2 mb-sm-0">
              <b-form-input
                id="inline-form-input-email"
                v-model="form.email"
                type="email"
                placeholder="Email"
                required
              ></b-form-input>
            </b-input-group>

            <b-button  type="submit" variant="outline-light">Subscribe</b-button>
          </b-form>
        </b-col>

          <b-modal ref="my-modal-success" id="modal-footer-sm" title="Success" button-size="sm">
          <p class="my-2">Email has been sent. We will contact to you soon. Thank You!</p>
          </b-modal>        
          <b-modal ref="my-modal-error" id="modal-footer-sm" title="Error" button-size="sm">
            <p class="my-2">Oops, something went wrong, please try again.</p>
          </b-modal>
      </b-row>

      <b-row align-h="center" class="footer-copyright">
        <p>© 2021 DistinguishedSociety</p>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      form: {
        email: ""
      },
      show: true,
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();

      let payload = { 
        email: this.form.email,
      };

      this.axios.post('http://distinguishedsociety.com/subscribe.php', payload)
        .then( () => {
          this.$refs['my-modal-success'].show();

          this.form.email = "";
    
          // Trick to reset/clear native browser form validation state
          this.show = false;
          this.$nextTick(() => {
            this.show = true;
          });
          setTimeout(() => {
            this.$refs['my-modal-success'].hide();
          }, 3000);
        })
        .catch(() => {
          this.$refs['my-modal-error'].show();
          setTimeout(() => {
            this.$refs['my-modal-error'].hide();
          }, 3000);
        });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer {
  background: #343a40;
  color: #fff;
  min-height: 200px;
  flex-shrink: 0;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

.b-icon {
  margin: 0 15px;
}

.footer-copyright {
  padding: 1rem 0;
}

.instagram {
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 4px;
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  box-shadow: 0px 3px 10px rgba(0,0,0,.25);
  margin-left: 10px;
}
.instagram:hover {
  opacity: 0.6;
}
.instagram svg {
  margin: 0;
}
</style>
