<template>
  <div class="wrapper-main">
    <div id="app">
      <Nav/>
      <router-view/>
    </div>
    <Footer/>
  </div>

</template>


<script>
import Footer from '@/components/Footer.vue'
import Nav from '@/components/Nav.vue'

export default {
  components: {
    Nav,
    Footer
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: "PF Muse Regular";
  src: local("PF Muse Regular"),
   url(./fonts/Muse-Regular.ttf) format("truetype");
}

html, body {
  height: 100%;
}

#app {
  font-family: "PF Muse Regular", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  text-align: center;
  color: #222;
  background: url("./assets/bg.jpg");
  background-size: 100% 100%;
  flex: 1 0 auto;
}

.wrapper-main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

footer {
    flex-shrink: 0;
}
</style>
