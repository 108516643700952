<template>
<div>
  <b-navbar toggleable="lg" type="dark" variant="dark">
    <b-navbar-brand href="/"><img style="width: 50px" alt="DistinguishedSociety" src="../assets/logo-white.svg"><img class="logo" alt="DistinguishedSociety" src="../assets/distinguishedsociety-white.svg"></b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <router-link class="nav-link" to="/">Home</router-link>
        <router-link class="nav-link" to="/about">About Us</router-link>
        <router-link class="nav-link" to="/services">Services</router-link>
        <router-link class="nav-link" to="/faq">FAQ</router-link>
        <router-link class="nav-link" to="/contact">Contact</router-link>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
</template>

<script>
export default {
  name: 'Nav'
}
</script>

<style scoped>
.logo {
  height: 75px;
}

.router-link-exact-active {
  color: #fff!important;
}

@media screen and (max-width: 500px) {
  .logo {
    height: 45px;
  }
}
</style>